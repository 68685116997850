import MicroModal from 'micromodal';
import customEventBus from './eventBus';
const configs = {
    openTrigger: 'data-modal-trigger',
    closeTrigger: 'data-modal-close',
    // disableScroll: true,
    onShow: (modal, trigger) => {
        customEventBus.emit('modal:show', { modalId: modal.id, trigger: trigger, modal: modal });
        document.querySelector('html').classList.add('modal-open');
        if (modal.id === 'modal-feedback-error' || modal.id === 'modal-feedback-success') {
            const modalElement = document.querySelector(`#${modal.id}`);
            if (modalElement) {
                let modalDialog = modalElement.querySelector('.modal--micromodal-dialog');
                if (modalDialog) {
                    modalDialog.focus();
                }
                /*                 if (!modalElement.getAttribute('data-error-customer')) {
                                    let page: HTMLElement = document.querySelector('.page');
                                    if (page) {
                                        let closeTime = parseInt(page.dataset.timeCloseModal, 10) * 1000;
                                        setTimeout(() => {
                                        micromodalInstance.forceClose(modal.modalId);
                                        }, closeTime);
                                    }
                                } */
            }
        }
    },
    onClose: (modal, trigger, event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        customEventBus.emit('modal:close', { modalId: modal.id });
        document.querySelector('html').classList.remove('modal-open');
    }
};
const micromodalInstance = {
    init() {
        MicroModal.init(configs);
    },
    forceShow(modalId) {
        MicroModal.show(modalId, configs);
    },
    forceClose(modalId) {
        MicroModal.close(modalId);
    },
};
export default micromodalInstance;
